<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <islogin />
    <div class="content">
      <div class="cons">
        <div class="cons-title">论文拟题</div>
        <div class="cons-title-subtitle">
          <p>
            写作前：输入研究方向，分析热门选题，提供题目参考，推荐参考文献。写作后：输入论文内容，系统全文分析，优化现有题目，推荐中图分类。
          </p>
        </div>
        <div class="cons-form">
          <el-form
            :model="formfc"
            label-width="120px"
            :rules="baseRulesfc"
            ref="ruleFormReffc"
          >
            <div class="custom-block">
              输入一句话，点击分词按钮生成多个词语，点击生成标题根据分词结果生成多个标题待选
            </div>
            <el-form-item label="输入句子" required prop="juzi">
              <el-input v-model="formfc.juzi" placeholder="请输入一句话" />
              <el-button type="warning" @click="onSubmitfc(ruleFormReffc)"
                >开始分词</el-button
              >
            </el-form-item>
          </el-form>
          <div class="xianti-fcjg">
            <div class="xianti-fcjg-title">分词结果</div>
            <div class="xianti-fcjg-nr">
              <el-input v-model="fcjg" placeholder="" disabled />
              <el-button type="danger" @click="clickscbtfc">生成标题</el-button>
            </div>
          </div>
        </div>
        <div
          v-if="true"
          class="cons-form"
          v-loading="loading"
          element-loading-background="rgba(0,0,0,0)"
        >
          <el-form
            :model="form"
            label-width="120px"
            :rules="baseRules"
            ref="ruleFormRef"
          >
            <div class="custom-block">
              输入一个关键字，系统会分析生成多个关键词，选择关键词生成标题
            </div>
            <el-form-item label="关键词" required prop="kwword">
              <el-input v-model="form.kwword" placeholder="请输入关键词" />
              <el-button type="primary" @click="onSubmit(ruleFormRef)"
                >立即生成</el-button
              >
            </el-form-item>
            <div class="gjc-list" v-if="yxctemp.length > 0">
              <div class="gjc-list-title">已选词组</div>
              <div class="gjc-list-con">
                <div
                  class="gjc-list-cons"
                  v-for="(item, key) in yxctemp"
                  :key="key"
                >
                  <el-tag
                    size="large"
                    type="warning"
                    closable
                    :disable-transitions="false"
                    @close="deletegjc(item, key)"
                    >{{ item.kw }}</el-tag
                  >
                </div>
              </div>
              <div class="xc-scbt">
                <el-button type="danger" @click="clickscbt">生成标题</el-button>
              </div>
            </div>
          </el-form>
        </div>
        <div class="xuanti" v-if="true">
          <div class="custom-block">
            以下是系统为您生成的关键词组，点击其中任意一个关键词可以结合当前关键词继续生成新的关键词组，也可点击上方“立即生成”按钮重新生成词组
          </div>
          <div class="xuanti-ul">
            <div class="gjc-list" v-if="easylist.length > 0">
              <div class="gjc-list-title">简单词组</div>
              <div class="gjc-list-con">
                <div
                  class="gjc-list-cons"
                  v-for="(item, key) in easylist"
                  :key="key"
                  @click="clickgjc(item)"
                >
                  <el-tag size="large" type="success">{{ item.kw }}</el-tag>
                </div>
              </div>
            </div>
            <div class="gjc-list" v-if="solist.length > 0">
              <div class="gjc-list-title">普通词组</div>
              <div class="gjc-list-con">
                <div
                  class="gjc-list-cons"
                  v-for="(item, key) in solist"
                  :key="key"
                  @click="clickgjc(item)"
                >
                  <el-tag size="large">{{ item.kw }}</el-tag>
                </div>
              </div>
            </div>
            <div class="gjc-list" v-if="difflist.length > 0">
              <div class="gjc-list-title">复杂词组</div>
              <div class="gjc-list-con">
                <div
                  class="gjc-list-cons"
                  v-for="(item, key) in difflist"
                  :key="key"
                  @click="clickgjc(item)"
                >
                  <el-tag size="large" type="danger">{{ item.kw }}</el-tag>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
// loading
let loading = ref(false);
let form = reactive({
  kwword: "",
});
let formfc = reactive({
  juzi: "",
});
let fcjg = ref("");
let loadingtext = ref("");
// 提交的数据
let key = ref("");
let kt = ref("");
let yxkey = ref("");
let yxctemp = reactive([]);
// 返回的数据
let solist = reactive([]);
let easylist = reactive([]);
let difflist = reactive([]);

// 表单验证
const ruleFormRef = ref();
const ruleFormReffc = ref();
const baseRules = {
  kwword: [{ required: true, message: "请输入关键词", trigger: "blur" }],
};
const baseRulesfc = {
  juzi: [{ required: true, message: "请输入一句话", trigger: "blur" }],
};

// 分词
const onSubmitfc = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      loading.value = true;
      post("/journalCommon/SubWordCut", {
        key: formfc.juzi,
        kt: "",
        yxkey: "",
      }).then((res) => {
        loading.value = false;
        // console.log(res);
        if (res.data && res.data.length > 0) {
          res.data.forEach((e, i) => {
            //
            if (i < res.data.length - 1) {
              fcjg.value += e + ",";
            } else {
              fcjg.value += e;
            }
          });
        }
      });
    }
  });
};

// 生成标题分词
const clickscbtfc = () => {
  if (fcjg.value.split(",").length < 5) {
    ElMessage({
      message: "不能低于5个词语",
      type: "warning",
    });
    return false;
  }
  router.push({
    path: "/xtzs/sbu",
    query: {
      key: encodeURIComponent(fcjg.value),
    },
  });
};

// 生成标题
const clickscbt = () => {
  let keywordstr = "";
  yxctemp.forEach((e, i) => {
    if (i < yxctemp.length - 1) {
      keywordstr += e.kw + ",";
    } else {
      keywordstr += e.kw;
    }
  });
  router.push({
    path: "/xtzs/sbu",
    query: {
      key: encodeURIComponent(keywordstr),
    },
  });
};

// 生成新词组
const clickgjc = (kword) => {
  // 本地显示已选词
  yxctemp.push(kword);

  // kt不为空，key添加到yxkey，key=kt，kt=新词   kt为空，kt=新词
  if (kt.value != "") {
    if (yxkey.value != "") {
      yxkey.value += "," + key.value;
    } else {
      yxkey.value += key.value;
    }
    key.value = kt.value;
    kt.value = kword.kw;
  } else {
    kt.value = kword.kw;
  }
  getkey();
};

// 提交
const onSubmit = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      // 第一次提交
      key.value = form.kwword;
      kt.value = "";
      yxkey.value = "";
      yxctemp.push({
        kw: form.kwword,
      });
      getkey();
    }
  });
};

// 删除关键词
const deletegjc = (keyword, key) => {
  yxctemp.splice(key, 1);
};

// 获取关键词
const getkey = () => {
  loading.value = true;
  post("http://server-1:8055/api/Lw/GetXuantiKey", {   //暂时未使用
    key: key.value,
    kt: kt.value,
    yxkey: yxkey.value,
  })
    .then((res) => {
      loading.value = false;
      solist.length = 0;
      easylist.length = 0;
      difflist.length = 0;
      if (res.data === null) {
        ElMessage({
          message: "没有更多词组",
          type: "warning",
          duration: 1000,
        });
      } else {
        solist.push(...res.data.solist);
        easylist.push(...res.data.easylist);
        difflist.push(...res.data.difflist);
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
}
:deep(.el-form-item__content) {
  flex-wrap: nowrap;
}
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons-form {
  width: 100%;
}
.cons-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.cons-subtitle {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #f5f7fa;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin: auto;
  margin-bottom: 30px;
}
.custom-block {
  padding: 8px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 3px solid #409eff;
  margin: 15px 0 15px 0;
  font-size: 14px;
}
.con-btn {
  text-align: center;
}

.xuanti {
  margin-top: 15px;
}

.gjc-list {
  cursor: pointer;
}
.gjc-list::after {
  content: "";
  display: table;
  clear: both;
}
.gjc-list-con {
  float: left;
  width: calc(100% - 80px);
}
.gjc-list-con:after {
  content: "";
  display: table;
  clear: both;
}
.gjc-list-cons {
  float: left;
  margin: 0 10px 10px 0;
}
.gjc-list-title {
  float: left;
  width: 80px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: bold;
}
.yxgjc {
  font-size: 14px;
}
.xc-scbt {
  width: 100%;
  float: left;
  padding-left: 80px;
  box-sizing: border-box;
}
.xianti-fcjg {
  display: flex;
  justify-content: space-between;
}
.xianti-fcjg-title {
  width: 120px;
  height: 32px;
  background: #f5f7fa;
  padding-right: 12px;
  box-sizing: border-box;
  font-size: 14px;
  color: #606266;
  line-height: 32px;
  text-align: right;
}
.xianti-fcjg-nr {
  width: calc(100% - 120px);
  height: 32px;
  display: flex;
  justify-content: space-between;
}

.cons-title-subtitle {
  color: #999;
  font-size: 14px;
}
.cons-title-subtitle p {
  margin: 0;
  padding: 0;
  text-align: center;
}
</style>
